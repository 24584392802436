import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { isAdmin } from "../../../../hooks/permissions";

// Actions
import { resendInviteEmail } from "../../../../redux/actions/user-actions";
import { success, failure } from "../../../../redux/actions/snackbar-actions";

// Components
import EditUserModal from "../EditUserModal";
import DeleteModal from "../DeleteModal";
import Loader from "../../../General/Loader";

// Utils
import { dateFormat } from "../../../../utils/date-format";
import { MESSAGES } from "../../../../utils/message";

// custom hook
import useScreenWidth from "../../../../hooks/useScreenWidth";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  Tooltip,
  IconButton,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import resend from "../../../../assets/images/user-table/resend.svg";
import edit from "../../../../assets/images/user-table/edit.svg";
import deleteicon from "../../../../assets/images/user-table/delete.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styles
import "./styles.scss";
import { USER_ROLES, USER_STATUS } from "../../../../utils/constants";
import { stringLength } from "../../../../utils/helperFunctions";
import { displayTooltip } from "../../../../hooks/displayTooltip";

const UserTable = ({ pagination, updatePagination }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [user, setSelectedUser] = useState(null);
  const [editUserModal, setEditUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  const dispatch = useDispatch();

  const rows =
    useSelector((state) => state.userReducers.teamMembers.rows) || [];
  const to = useSelector((state) => state.userReducers.teamMembers.to);
  const from = useSelector((state) => state.userReducers.teamMembers.from);
  const lastPage = useSelector(
    (state) => state.userReducers.teamMembers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.userReducers.teamMembers.currentPage || 1
  );

  const isLoading = useSelector((state) => state.userReducers.loading);
  const loaded = useSelector((state) => state.userReducers.teamMembers) || [];

  const rowsCount =
    useSelector((state) => state.userReducers.teamMembers.count) || 0;

  const currentUser = useSelector((state) => state.userReducers.user);

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const isTooltip = displayTooltip();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeRowId, setActiveRowId] = useState(null);
  const isScreen = useScreenWidth();

  const columns = [
    {
      field: "fullName",
      headerName: "Full Name",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <>
          {params.row.status === "Invited" ? (
            <Box className="addressWrapper">
              <Typography> -</Typography>
            </Box>
          ) : stringLength(
              `${params.row.firstName}` + `${params.row.lastName}`
            ) ? (
            <Tooltip
              title={params.row.firstName + " " + params.row.lastName}
              disableHoverListener={isTooltip}
            >
              <Box className="addressWrapper">
                <Typography>
                  {params.row.firstName + " " + params.row.lastName}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <Box className="addressWrapper">
              <Typography>
                {params.row.firstName + " " + params.row.lastName}
              </Typography>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 250,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.email}`, 20) ? (
          <Tooltip title={params.row.email} disableHoverListener={isTooltip}>
            <Box className="addressWrapper">
              <Typography>{params.row.email}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.email}</Typography>
          </Box>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>
            {USER_STATUS[params?.row?.status?.toUpperCase()]}
          </Typography>
        </Box>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <>
          {params?.row?.role?.slug ? (
            <Box className="addressWrapper">
              <Typography>
                {
                  USER_ROLES.find((role) => {
                    return params?.row?.role?.slug === role.value;
                  })?.title
                }
              </Typography>
            </Box>
          ) : (
            <Box className="addressWrapper">
              <Typography> -</Typography>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 250,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{dateFormat(params.row.createdAt)}</Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <>
          {currentUser.id !== params.row.id &&
            currentUser.isOrgAdmin &&
            !params.row.isSystemAdmin &&
            !params.row.isOrganizationOwner &&
            (isScreen ? (
              <Box className="btnWrapper">
                {params.row?.status === "Invited" && (
                  <Tooltip title="Resend invite">
                    <IconButton
                      onClick={() => resendInvite(params.row.id)}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <img src={resend} alt="resendIcon" />
                    </IconButton>
                  </Tooltip>
                )}
                {params.row?.status !== "Invited" && (
                  <Box className="placeholder" />
                )}
                <Tooltip title="Edit User">
                  <IconButton
                    onClick={() => {
                      setSelectedUser(params.row), setEditUserModal(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <img src={edit} alt="resendIcon" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => {
                      setSelectedUser(params.row.id);
                      setDeleteUserModal(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <img src={deleteicon} alt="resendIcon" />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => {
                    setActiveRowId(params.row); // Set active row ID when the menu is opened
                    handleClick(event);
                  }}
                >
                  <MoreVertIcon
                    sx={{
                      color:
                        activeRowId?.id === params.row.id && anchorEl
                          ? "#ed5c2f"
                          : "inherit",
                    }}
                  />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxWidth: "100px",
                    },
                    className: "actionDropdown",
                  }}
                >
                  {activeRowId?.status === "Invited" && (
                    <MenuItem
                      onClick={() => {
                        resendInvite(activeRowId?.id);
                        handleClose();
                      }}
                    >
                      <img src={resend} alt="resend" />
                      Resend
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      setSelectedUser(activeRowId), setEditUserModal(true);
                      handleClose();
                    }}
                  >
                    <img src={edit} alt="edit" />
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSelectedUser(activeRowId?.id);
                      setDeleteUserModal(true);
                      handleClose();
                    }}
                  >
                    <img src={deleteicon} alt="deleteicon" />
                    Delete
                  </MenuItem>
                </Menu>
              </Box>
            ))}
        </>
      ),
    },
  ];

  const resendInvite = async (payload) => {
    const response = await dispatch(resendInviteEmail(payload));
    if (response.status === 200) {
      dispatch(success(response.data?.message));
    } else {
      dispatch(failure(response.data?.message));
    }
  };

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="userTableWrapper"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSizeOptions={[20, 50, 100]}
        rowCount={rowsCount || 0}
        pagination={pagination}
        paginationMode="server"
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="userTableGrid"
        components={{
          NoRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              {Object.keys(loaded).length
                ? MESSAGES.ACCOUNT.USERS.NO_RESULT_TEXT
                : ""}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="paginationWrapper">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Typography>
          Showing {rowsCount ? from : 0} to{" "}
          {rowsCount < to ? rows?.length + from - 1 : to} of {rowsCount} results
        </Typography>
      </Box>
      <EditUserModal
        openModal={editUserModal}
        handleCloseModal={() => setEditUserModal(false)}
        selectedUser={user}
      />
      <DeleteModal
        openModal={deleteUserModal}
        handleCloseModal={() => setDeleteUserModal(false)}
        selectedUser={user}
      />
    </div>
  );
};

export default UserTable;
